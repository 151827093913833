import Topbar from "components/navbar";
import Sidebar1 from "components/sidebar";
import { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "setup/auth";
import "setup/requireAuth/index.scss";
import FloatingActionButtonSize from "components/floatButton";

const RequireAuth = ({ allowedRoles }) => {
  const { currentUser, userType, GetUserType } = useAuth();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userRoles, setUserRoles] = useState(userType || []);

  useEffect(() => {
    const fetchUserType = async () => {
      if (currentUser && (!userType || userType.length === 0)) {
        try {
          const data = await GetUserType(currentUser);
          setUserRoles(data);
        } catch (error) {
          console.error("Error fetching user type:", error);
        }
      }
    };

    fetchUserType();
  }, [currentUser, userType, GetUserType]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!currentUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!userRoles.find(item => allowedRoles.includes(item))) {
    return <Navigate to="/403" state={{ from: location }} replace />;
  }

  return (
    <div className="app">
      <div className="topbar">
        <Topbar 
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
        />
      </div>
      <div className="main-content">
        <Sidebar1 
          sidebar={userRoles.find(item => item === "2009")}
          isCollapsed={isCollapsed}
        />
        <div className="content">
          <FloatingActionButtonSize />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default RequireAuth;