import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { collection, doc, setDoc, getFirestore, Timestamp, query, where, getDocs } from "firebase/firestore"; 
import firebaseApp from "setup/firebase";
import EmailUsers from 'components/emailUsers/index.js';
import "pages/notifications/index.scss"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Index() {
  const [notificationInfo, setNotificationInfo] = useState('');
  const [notificationHeader, setNotificationHeader] = useState('');

  const [notificationLink, setNotificationLink] = useState('');

  const [notificationYear, setNotificationYear] = useState('');
  const [success, setsuccess] = useState(null);

  const db = getFirestore(firebaseApp);

  const handleInfoChange = (content) => {
    setNotificationInfo(content);
  };
  const handleLinkChange = (event) => {
    setNotificationLink(event.target.value);
  };
  const handleHeaderChange = (event) => {
    setNotificationHeader(event.target.value);
  };

  const handleYearChange = (event) => {
    setNotificationYear(event.target.value);
  };

  const getUsersByYear = async (year) => {
    const usersRef = collection(db, "Users");
    const q = query(usersRef, where("userYear", "==", year));
    const querySnapshot = await getDocs(q);
    const userEmails = [];
    querySnapshot.forEach((doc) => {
      userEmails.push(doc.id); 
    });
    console.log("Fetched emails: ", userEmails); 
    return userEmails;
  };

  const sendEmails = async (userEmails, notificationHeader, notificationInfo) => {
    const subject = 'Symco Notification: ' + notificationHeader; 
    const message = `
      Good day,
      <br><br>
      <strong>You have a new Notification:</strong>
      <br><br>
      <strong>${notificationHeader}</strong>
      <br><br>
      ${notificationInfo}
      <br><br>
      Go to <a href="https://login.symco.co.za">Login</a> to view all your notifications!
      <br><br>
      Thank you<br>
      The Symco Team
    `;

    try {
      const response = await EmailUsers(subject, message, userEmails.join(','));
      console.log('Emails sent successfully', response);
    } catch (error) {
      console.error('Error sending emails:', error);
    }
  };

  const handleSubmit = async () => {
    setsuccess('Loading');
    
    if (notificationHeader !== null && notificationHeader.trim() !== '' &&
        notificationInfo !== null && notificationInfo.trim() !== '' &&
        notificationYear !== null && notificationYear.trim() !== '') {
      try {
        const newNotification = {
          notificationHeader: notificationHeader,
          notificationInfo: notificationInfo,
          notificationLink: notificationLink === "" ? "NA" : notificationLink,
          notificationTimeDate: Timestamp.fromDate(new Date()),
          notificationYear: notificationYear,
        };

        var date = new Date();
        var seconds = date.getTime();
        await setDoc(doc(db, "Notifications", seconds.toString()), newNotification);

        const userEmails = await getUsersByYear(notificationYear);
        await sendEmails(userEmails, notificationHeader, notificationInfo);
        
        setsuccess('Success');
      } catch (error) {
        console.error('Error:', error);
        setsuccess('Failed');
      }
    } else {
      setsuccess('Required');
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link',
    'color', 'background'
  ];

  return (
    <Box className="notifications">
    <Box className="notifications-inner">
      <h1>Notifications</h1>
      <Box className="select">
        <InputLabel id="demo-simple-select-label">Year</InputLabel>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            className="selectIn"
            value={notificationYear}
            label="Year"
            onChange={handleYearChange}
          >
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2024">2024</MenuItem>
            <MenuItem value="2025">2025</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="textarebox">
      <TextField
          className="TextField"
          fullWidth 
          id="outlined-textarea1"
          label="Header"
          placeholder="Bold Text"
          multiline
          value={notificationHeader} 
          onChange={handleHeaderChange}
          required
        />
        <ReactQuill
          className="rich-text-editor"
          theme="snow"
          modules={modules}
          formats={formats}
          value={notificationInfo}
          onChange={handleInfoChange}
          placeholder="More Information"
        />
        <TextField
        className="TextField"
          fullWidth 
          id="outlined-textarea3"
          label="Link"
          placeholder="Link"
          multiline
          value={notificationLink} 
          onChange={handleLinkChange}
        />
         <Typography variant="body2" component="div"  className='Typ'>** Link Please include whole link like: https://www.google.com/</Typography>
        
         Only 1 link per notification **
        {/* <textarea className="input-label" value={notificationInfo} onChange={handleInfoChange} /> */}
      </Box>

      

      <Box className="buttonbox">
        <Button onClick={handleSubmit} >
          Send
        </Button>
        {success==='Loading' && (<Alert variant="outlined" severity="info">loading</Alert>)}
        {success==='Success' && (<Alert variant="outlined" severity="success">Notification and Email: Successfull</Alert>)}
        {success==='Failed' && (<Alert variant="outlined" severity="error">Notification and Email:Failed </Alert>)}
        {success==='Required' && (<Alert variant="outlined" severity="error">Please make sure Year, Header and Description is filled in</Alert>)}


        
      </Box>
    </Box>
    </Box>

  );
}
