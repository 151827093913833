import { Box, IconButton, Menu, Typography } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from "content/symcoicon.png"
import "components/navbar/index.scss"
import { useAuth } from "setup/auth";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const Topbar = ({ toggleMobileMenu, isCollapsed, toggleCollapse }) => {
  const { logout } = useAuth();

  async function logg() {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box className="navbarBox">
      <div className="leftSection">
        <IconButton onClick={toggleCollapse} className="menuButton">
          <MenuOutlinedIcon />
        </IconButton>
        <Typography variant="h4" className="title">
          Symco
        </Typography>
      </div>

      <Box className="logo">
        <img alt="profile-user" src={logo} />
      </Box>

      <Box className="rightBox">
        <IconButton onClick={logg}>
          <Typography className="innerText">Sign Out</Typography>
          <ExitToAppIcon className="innerButton" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
