import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useAuth } from 'setup/auth';
import "pages/delegate_dash/index.scss"
import { GetPersonalNotifications } from 'setup/db_calls';
import { GetNotifications } from 'setup/db_calls';
import { Link } from 'react-router-dom';

 function DelegateDash() {
    const {currentUser,userInfo } = useAuth();
    const [notifications, setNotifications] = useState(null);
    const [data, setData] = useState(null);

  useEffect(() => {
    const GetInfo = async () => {
      try {
        const response = await GetPersonalNotifications(currentUser);
        const holder = response.reverse()
        setData(holder);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    const GetNotiInfo = async () => {
      try {
        const querry = await GetNotifications()
        const holder = querry.reverse()
        setNotifications(holder)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    GetInfo();
    GetNotiInfo();
  }, [currentUser]);
  
  return (
   <Box className = "delegateDash">
    {/* NOTIFICATIONS */}
    <Box className = "notifications">
      <h2>Notifications</h2>
      <div className="stackbox-container">
        <Stack className='stackbox' direction="column" spacing={2} >
        {notifications && notifications.length > 0 ? (
              notifications.map((results, index) => (

                userInfo?.userYear === results['notificationYear']?
              
                <Card key={index} className="notification-card">
                  <CardContent>
                    <Typography className="year-badge" color="text.secondary">
                      {results['notificationYear']}
                    </Typography>
                    <Typography variant="h5" className="notification-header">
                      {results['notificationHeader']}
                    </Typography>
                    <div 
                      className="notification-content"
                      dangerouslySetInnerHTML={{ 
                        __html: results['notificationInfo']?.replace(/<p><br><\/p>/g, '') || ''
                      }}
                    />
                    {results['notificationLink'] !== "NA" && (
                      <Link 
                        target="_blank" 
                        to={results['notificationLink']} 
                        className="notification-link"
                      >
                        {results['notificationLink']}
                      </Link>
                    )}
                    <Typography className="timestamp">
                      {new Date(results['notificationTimeDate'] * 1000)
                        .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})}
                    </Typography>
                  </CardContent>
                </Card>
                : null
              ))
            
            ) : (
              <Typography>No notifications available.</Typography>
             
            )}
          
        </Stack>
      </div>
    </Box>
    {/* END NOTIFICATIONS */}

    {/* PERSONAL NOTIFICATIONS */}
    <Box className = "personalNotifications">
      <h2>Personal Messages</h2>
      <div className="stackbox-container">
        <Stack className='stackbox' direction="column" spacing={2} >
          
          {data && data.length > 0 ? (
            data.map((element, key) => (
              <Card key={key} className="notification-card">
                <CardContent>
                  <Typography variant="h5" className="notification-header">
                    {element.personalNotificationsText}
                  </Typography>
                  <Typography className="timestamp">
                    {new Date(element.personalNotificationsDate.seconds*1000)
                      .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No personal messages available.</Typography>
          )}
          
        </Stack>
      </div>
    </Box>
    {/* END PERSONAL NOTIFICATIONS */}
   </Box>
    
  )
}

export default DelegateDash