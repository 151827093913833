
import firebaseApp from "setup/firebase";

import { doc, setDoc ,getFirestore,arrayUnion, getDoc,deleteDoc,Timestamp, updateDoc } from "firebase/firestore"; 
import { useAuth } from "setup/auth";

const db = getFirestore(firebaseApp);

export async function AddPersonalNoti(useremail,message) {
    const localDateTime = new Date();
    try {
        console.log(useremail)
        console.log(message)

        const docRef = doc(db, "Users", useremail[0]);
        await updateDoc(docRef, {
            personalNotifications: arrayUnion({
              personalNotificationsText: message,
              personalNotificationsDate: Timestamp.fromDate(localDateTime),
            }),
          });
    return "true"    
} catch (error) {
        return error
    }

    
}

 export function DeleteUserFromDB(userEmail) {

    try {
        userEmail.forEach(async element => {
            try {
                 await deleteDoc(doc(db, "Users", element));


            } catch (error) {
                console.log(error)
            }
             
        });
        return true
    } catch (error) {
        return error
        
    }
 }

 



 
export default async function AddUserToDB(userEmail,userType,userYear)
{
    try {
        const docRef = doc(db, "Users",userEmail );
        const docSnap = await getDoc(docRef);
        const localDateTime = new Date();
         
      if (!docSnap.exists()) {
        
       const hh = await setDoc(doc(db, "Users", userEmail), {
            userYear: userYear,
            usertype: userType==='Admin'? ['2001','2009']:['2001'],

            contractdoc: [],
            contractstatus: false,
            medicalallergies: "",
            medicaldietaryreq :  "",
            medicaldoc: [],
            medicalstatus: false,
            paymentdoc : [],
            paymentfuffiled :false,
            paymentplan:  "",
            paymentstatus : false,
            travelarrivaldate: Timestamp.fromDate(new Date("December 9, 2023")),
            traveldeparturedate: Timestamp.fromDate(new Date("December 9, 2023")),
            travelDOC: [],
            travelflightno: "",
            travelstatus: false,
            userCountry : "",
            userID: [],
            usercontactnumber : "",
            userfullname: "",
            userSurname: "",
            userpassport : [],
            userpfp: "",
            userprefname: "",
            userstatus: false,
            useruniversity: "",
            personalNotifications: [{
                  personalNotificationsText: "Welcome To Symco",
                  personalNotificationsDate: Timestamp.fromDate(localDateTime),
                }],


          });
          return hh=== undefined? 'success': 'an Error has occured'
        
        }
        else {
            return 'Email Already Exists'
        }
    } catch (error) {
        return (error)
    }    
}
