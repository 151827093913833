import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useAuth } from "setup/auth";
import { fetchUserData, updateUserDetails } from "setup/db_calls";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "pages/user_profile/payment_info/index.scss";

export default function BasicSelect() {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [paymentDocFile, setPaymentDocFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [paymentDocs, setPaymentDocs] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [paymentFuffiled, setPaymentFuffiled] = useState(false);
  // const [paymentOption, setPaymentOption] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (currentUser && currentUser.email) {
        const data = await fetchUserData(currentUser.email);
        if (data) {
          setUserData(data);
          setSelectedPlan(
            "Full Payment"
            // data.paymentplan === "" ? "Full Payment" : data.paymentplan
          );
          setPaymentDocs(data.paymentdoc || []);
          setPaymentFuffiled(data.paymentfuffiled || false);
          // setPaymentOption(!data.paymentstatus);
        }
      }
    }

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    setIsComplete(selectedPlan !== "" && paymentDocs.length > 0);
  }, [selectedPlan, paymentDocs]);

  // const handlePlanChange = (event) => {
  //   setSelectedPlan(event.target.value);
  // };
  const handlePaymentChange = (event) => {
    // console.log(event.target.checked);
    setPaymentFuffiled(event.target.checked);
  };
  // const handlePaymentOptionChange = (event) => {
  //   // console.log(event.target.checked);
  //   setPaymentOption(event.target.checked);
  // };

  const handleFileChange = (event) => {
    setPaymentDocFile(event.target.files[0]);
  };

  const uploadDocument = async () => {
    if (!paymentDocFile || !currentUser) return;

    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `payment_documents/${currentUser.uid}/${paymentDocFile.name}`
    );
    await uploadBytes(storageRef, paymentDocFile);
    const url = await getDownloadURL(storageRef);

    const userDetails = await fetchUserData(currentUser.email);
    const updatedDetails = {
      ...userDetails,
      paymentdoc: userDetails.paymentdoc
        ? [...userDetails.paymentdoc, url]
        : [url],
    };

    await updateUserDetails(currentUser.email, updatedDetails);

    setPaymentDocs((prevDocs) => [...prevDocs, url]);
    setUploading(false);

    alert("Document successfully uploaded."); // Alert for successful document upload
  };

  const handleSubmit = async () => {
    if (currentUser && currentUser.email && isComplete) {
      const updatedDetails = {
        ...userData,
        // paymentplan: paymentOption ? selectedPlan : "Full Payment",
        paymentdoc: paymentDocs,
        paymentfuffiled:  paymentFuffiled  ,
        paymentstatus:paymentFuffiled? true : false,
      };
      await updateUserDetails(currentUser.email, updatedDetails);

      alert("User details successfully updated."); // Alert for successful update
    }
  };

  return (
    <Box className="payment-container">
      <div className="payment-container-inner">
        <h2 className="payment-title">Payment Info</h2>
        {/* <Box className="paymentPlan">
          <Typography>One Full Payment Option</Typography>
          <Switch
            checked={paymentOption}
            className="chkForm"
            size="large"
            value={paymentOption}
            onChange={handlePaymentOptionChange}
          />
          <Typography>3 Payment Option</Typography>
        </Box> */}
        <div className="content-block">
          {/* <Box className="form-control dropdown">
            <InputLabel id="demo-simple-select-label">Payment Plan</InputLabel>
            <FormControl fullWidth>
              <Select
                disabled={!paymentOption}
                // defaultValue="Full Payment"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPlan}
                label="Payment Plan"
                onChange={handlePlanChange}
                renderValue={(selectedPlan) => {
                  return selectedPlan;
                }}
              >
                <MenuItem value="FullPayment" disabled>
                  Full Payment
                </MenuItem>
                <MenuItem value="Payment 1">Payment 1</MenuItem>
                <MenuItem value="Payment 2">Payment 2</MenuItem>
                <MenuItem value="Payment 3">Payment 3</MenuItem>
              </Select>
            </FormControl>
          </Box> */}

          <Box className="form-control upload-section">
            <input type="file" onChange={handleFileChange} />
            <Button
              variant="contained"
              color="primary"
              onClick={uploadDocument}
              disabled={uploading}
              className="upload-button"
            >
              {uploading ? "Uploading..." : "Upload Document"}
            </Button>
          </Box>

          <Box className="form-control">
            {paymentDocs.map((docUrl, index) => (
              <div key={index}>
                <a href={docUrl} target="_blank" rel="noopener noreferrer">
                  Document {index + 1}
                </a>
              </div>
            ))}
          </Box>
          {/* {paymentOption && ( */}
            <Box>
              <FormGroup>
                <FormControlLabel
                  onChange={handlePaymentChange}
                  control={<Switch />}
                  defaultValue={false}
                  value={paymentFuffiled}
                  label="Total Payment Fuffiled"
                />
              </FormGroup>
            </Box>
          {/* )} */}

          <Box className="form-control">
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              disabled={!isComplete || uploading}
            >
              Submit
            </Button>
          </Box>
        </div>
      </div>
    </Box>
  );
}
