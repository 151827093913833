import React, { useEffect, useState } from 'react';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useAuth } from 'setup/auth';
import { TextField, Box, Button } from '@mui/material';
import { updateUserDetails, fetchUserData } from 'setup/db_calls';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import firebaseApp from 'setup/firebase';
import "pages/user_profile/travel_info/index.scss"

export default function BasicDateCalendar() {
  const { currentUser } = useAuth();
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [departureTime, setDepartureTime] = useState(null);
  const [travelFlighNo, setTravelFlighNo] = useState("");
  const [travelDocFile, setTravelDocFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [travelDocs, setTravelDocs] = useState([]);
  const [isComplete, setIsComplete] = useState(false);


  useEffect(() => {
    const fetchAndSetData = async () => {
      if (currentUser && currentUser.email) {
        const userDetails = await fetchUserData(currentUser.email);
        if (userDetails) {
          setTravelFlighNo(userDetails.travelflightno? userDetails.travelflightno : "");
          if (userDetails.travelarrivaldate) {
            const arrivalDateTime = dayjs(userDetails.travelarrivaldate.toDate());
            setArrivalDate(arrivalDateTime);
            setArrivalTime(arrivalDateTime);
            
          }
          if (userDetails.traveldeparturedate) {
            const departureDateTime = dayjs(userDetails.traveldeparturedate.toDate());
            setDepartureDate(departureDateTime);
            setDepartureTime(departureDateTime);
          }
          if (userDetails.travelDOC) {
            setTravelDocs(userDetails.travelDOC);
          }
        }
      }
    };

    fetchAndSetData();
  }, [currentUser]);

  useEffect(() => {
    if (
      arrivalDate && departureDate &&
      arrivalTime && departureTime &&
      travelDocs.length > 0 &&
      travelFlighNo !== ""
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [arrivalDate, departureDate, arrivalTime, departureTime, travelDocs]);

  const handleArrivalDateChange = (newValue) => {
    setArrivalDate(newValue);
  };

  const handleFlightNoChange = (newValue) => {
    console.log(newValue.target.value);
    setTravelFlighNo(newValue.target.value);
  };

  // const handleChange = (event) => {
  //   setUserData({ ...userData, [event.target.name]: event.target.value });
  // };
  const handleDepartureDateChange = (newValue) => {
    setDepartureDate(newValue);
  };

  const handleArrivalTimeChange = (newValue) => {
    setArrivalTime(newValue);
  };

  const handleDepartureTimeChange = (newValue) => {
    setDepartureTime(newValue);
  };

  const combineDateTime = (date, time) => {
    if (date && time) {
      let combined = date.hour(time.hour()).minute(time.minute());
      return combined.toDate();
    }
    return null;
  };

  const handleSubmit = async () => {
    try {
      // If there's a file waiting to be uploaded, upload it first
      if (travelDocFile) {
        await uploadDocument();
      }

      if (currentUser && currentUser.email) {
        const formattedArrivalDate = combineDateTime(arrivalDate, arrivalTime);
        const formattedDepartureDate = combineDateTime(departureDate, departureTime);

        await updateUserDetails(currentUser.email, {
          travelarrivaldate: formattedArrivalDate,
          traveldeparturedate: formattedDepartureDate,
          travelflightno: travelFlighNo,
          travelstatus: isComplete
        });

        alert('User details updated successfully.');
        setTravelDocFile(null); // Reset the file input after successful upload
      }
    } catch (error) {
      alert('Error updating details: ' + error.message);
    }
  };

  const handleFileChange = (event) => {
    setTravelDocFile(event.target.files[0]);
  };

  const uploadDocument = async () => {
    if (!travelDocFile || !currentUser) return;

    setUploading(true);
    try {
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage, `travel_documents/${currentUser.uid}/${travelDocFile.name}`);
      await uploadBytes(storageRef, travelDocFile);
      const url = await getDownloadURL(storageRef);

      const userDetails = await fetchUserData(currentUser.email);
      const updatedDetails = {
        ...userDetails,
        travelDOC: userDetails.travelDOC ? [...userDetails.travelDOC, url] : [url]
      };
    
      await updateUserDetails(currentUser.email, updatedDetails);
      setTravelDocs(prevDocs => [...prevDocs, url]);
    } catch (error) {
      throw new Error('Failed to upload document: ' + error.message);
    } finally {
      setUploading(false);
    }
  };


  return (
    <Box className="basic-date-calendar"> 
    <div className="basic-date-calendar-inner">
      <h2>Travel Info</h2> {/* Page Title */}

      <Box className="travel-flight-no">
        <TextField
          label="Travel Flight No"
          value={travelFlighNo||""}
          className="travel-flight-no-input"
          onChange={handleFlightNoChange}
         
        />
        {/* <TextField 
              label="Preferred Name" 
              name="userprefname" 
              value={userData?.userprefname || ''} 
              onChange={handleChange} 
            /> */}
      </Box>
  
      <Box className="date-time-picker">
        <h3>Arrival Information</h3> {/* Arrival Label */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Arrival Date"
            value={arrivalDate}
            onChange={handleArrivalDateChange}
            renderInput={(props) => <TextField {...props} />}
          />
          <TimePicker
            label="Arrival Time"
            value={arrivalTime}
            onChange={handleArrivalTimeChange}
            renderInput={(props) => <TextField {...props} />}
          />
        </LocalizationProvider>
      </Box>
  
      <Box className="date-time-picker">
        <h3>Departure Information</h3> {/* Departure Label */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Departure Date"
            value={departureDate}
            onChange={handleDepartureDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Departure Time"
            value={departureTime}
            onChange={handleDepartureTimeChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
  
      <Box className="document-upload">
        <h3>Travel Document Upload</h3>
        <input type="file" onChange={handleFileChange} />
      </Box>

      <Box className="form-control">
            {travelDocs.map((docUrl, index) => (
              <div key={index}>
                <a href={docUrl} target="_blank" rel="noopener noreferrer">
                  Document {index + 1}
                </a>
              </div>
            ))}
          </Box>
      <Box className="submit-section">
        <Button 
          className="submitBut"
          variant="contained" 
          onClick={handleSubmit} 
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Submit'}
        </Button>
      </Box>
    </div>
    </Box>
  );
}
