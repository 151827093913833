import React, { useEffect, useState } from 'react';
import { TextField, Box, Alert,CircularProgress  } from '@mui/material';
import { fetchUserData, updateUserDetails } from 'setup/db_calls';
import { useAuth } from 'setup/auth';
import 'pages/user_profile/personal_info/index.scss';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import firebaseApp from "setup/firebase";

export default function ProfilePersonal() {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(null); 
  const [idDoc, setIdDoc] = useState(null); 
  const [passportDoc, setPassportDoc] = useState(null); 
  const [loadingPFP, setLoadingPFP] = useState(null); 


  useEffect(() => {
    async function fetchData() {
      if (currentUser && currentUser.email) {
        const data = await fetchUserData(currentUser.email);
        if (data) {
          setUserData(data);
          setImageUrl(data.userpfp); 
        }
      }
    }
  
    fetchData();
  }, [currentUser]);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleImageUpload = async () => {
    setLoadingPFP("wait")
    if (!currentUser || !imageFile || !firebaseApp) {
    setLoadingPFP('Error: Missing required information for image upload.')

      // alert('Error: Missing required information for image upload.');
      return;
    }

    const storage = getStorage(firebaseApp);
    const userId = currentUser.uid;
    const storageRef = ref(storage, `profile_images/${userId}/${imageFile.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(storageRef);
      setUserData({ ...userData, userpfp: downloadURL });
      await updateUserDetails(currentUser.email, { userpfp: downloadURL });
      setImageUrl(downloadURL);
      // alert('Profile image uploaded successfully!');
      setLoadingPFP("succesfull")
    } catch (error) {
      // alert('Error uploading image. Please try again.');
      setLoadingPFP('Error uploading image. Please try again.')
    }
  };

  const handleIdDocChange = (event) => {
    setIdDoc(event.target.files[0]);
  };

  const handlePassportDocChange = (event) => {
    setPassportDoc(event.target.files[0]);
  };

  const uploadDocument = async (file, path) => {
    if (!file) return null;
    const storageRef = ref(getStorage(firebaseApp), path);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleDocumentsUpload = async () => {
    setLoading("wait");
    if (!passportDoc && !idDoc) {
    setLoading("Error: Missing required information for upload.");
      
    } else {
      
    
    try {
    const idUrl = await uploadDocument(idDoc, `documents/${currentUser.uid}/id`);
    const passportUrl = await uploadDocument(passportDoc, `documents/${currentUser.uid}/passport`);

    const newUserData = { ...userData };
    if (idUrl) newUserData.ID = [...(newUserData.ID || []), idUrl];
    if (passportUrl) newUserData.userpassport = [...(newUserData.userpassport || []), passportUrl];
    await updateUserDetails(currentUser.email, newUserData);
    setLoading("succesfull");
    setUserData(newUserData);

    } catch (error) {
      setLoading(error)
    }
  }
    // alert('Documents uploaded successfully!');
  };

  const handleSubmit = async () => {
    const isComplete = userData.userfullname && userData.userprefname && userData.userSurname 
      && userData.usercontactnumber && userData.userCountry && userData.useruniversity && userData.userpfp && userData.ID;
  
    const updatedUserData = { 
      ...userData,
      userstatus: Boolean(isComplete)
    };
  
    await updateUserDetails(currentUser.email, updatedUserData);
    setUserData(updatedUserData);
    alert('Profile details submitted successfully!');
  };

  const isSubmitEnabled = () => {
    const hasImage = imageUrl != null;
    const hasDocuments = (userData.ID && userData.ID.length > 0) || (userData.userpassport && userData.userpassport.length > 0);
    return hasImage && hasDocuments;
  };

  if (!userData) {
    return <div>Loading user data...</div>; 
  }

  return (
    <div className="page-container">
      <div className="profile-personal-container">
        <h2 className="personal-info-title">Personal Info</h2>
        <div className="content-section">
          <div className="left-side">
            {/* Text fields */}
            <TextField 
              label="Fullname" 
              name="userfullname" 
              value={userData?.userfullname || ''} 
              onChange={handleChange} 
            />
            <TextField 
              label="Preferred Name" 
              name="userprefname" 
              value={userData?.userprefname || ''} 
              onChange={handleChange} 
            />
            <TextField 
              label="Surname" 
              name="userSurname" 
              value={userData?.userSurname || ''} 
              onChange={handleChange} 
            />
            <TextField 
              label="Email Address" 
              value={currentUser?.email || ''} 
              InputProps={{ readOnly: true }} 
            />
            <TextField 
              label="Contact Number" 
              name="usercontactnumber" 
              value={userData?.usercontactnumber || ''} 
              onChange={handleChange} 
            />
            <TextField 
              label="Country" 
              name="userCountry" 
              value={userData?.userCountry || ''} 
              onChange={handleChange} 
            />
            <TextField 
              label="University" 
              name="useruniversity" 
              value={userData?.useruniversity || ''} 
              onChange={handleChange} 
            />
          </div>
  
          <div className="right-side">
            <label className="upload-label">Profile Image:</label>
            {imageUrl && (
              <img src={imageUrl} alt="Profile" className="profile-image" />
            )}
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {(loadingPFP=== null)?("")
                :(loadingPFP=== "wait")?
                              (<Box sx={{ display: 'flex' }}>
                                          <CircularProgress />
                                          </Box>)
                :(loadingPFP=== "succesfull")?
                                  (<Alert severity="success">
                                  successful
                                  </Alert>)
                :(<Alert severity="error"> 
                    {loadingPFP}
                  </Alert>)}
            <button className='submit-button' onClick={handleImageUpload}>Upload Image</button>
            
        
            {/* {loadingPFP && ( <Box sx={{ display: 'flex' }}>
                              <CircularProgress />
                              </Box>
            )} */}
  
            <label className="upload-label">ID Document:</label>
            <input type="file" onChange={handleIdDocChange} />
  
            <label className="upload-label">Passport Document:</label>
            <input type="file" onChange={handlePassportDocChange} />
            {/* {loading && <Alert severity="info">Uploading...</Alert>} */}
            {(loading=== null)?("")
                :(loading=== "wait")?
                              (<Box sx={{ display: 'flex' }}>
                                          <CircularProgress />
                                          </Box>)
                :(loading=== "succesfull")?
                                  (<Alert severity="success">
                                  successful
                                  </Alert>)
                :(<Alert severity="error"> 
                    {loading}
                  </Alert>)}
            <button className='submit-button' onClick={handleDocumentsUpload}>Upload Documents</button>
  
            {userData.ID && userData.ID.map((url, index) => (
              <div key={index}><a href={url} target="_blank" rel="noopener noreferrer">ID Document {index + 1}</a></div>
            ))}
            {userData.userpassport && userData.userpassport.map((url, index) => (
              <div key={index}><a href={url} target="_blank" rel="noopener noreferrer">Passport Document {index + 1}</a></div>
            ))}
          </div>
        </div>
        
        <div className="submit-button-container">
          <button 
            className={`submit-button ${!isSubmitEnabled() ? 'submit-button-disabled' : ''}`} 
            onClick={handleSubmit} 
            disabled={!isSubmitEnabled()}
          >
            Submit
          </button>
        </div>
        
        {!isSubmitEnabled() && (
          <Alert severity="info" sx={{ backgroundColor: 'red', color: 'white' }}>
            Please upload both a profile image and the required documents to enable the submit button.
          </Alert>
        )}
      </div>
    </div>
  );
}